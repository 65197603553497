app.controller('AdminNavController', ['$scope', '$mdSidenav', '$log', function ($scope, $mdSidenav, $log) {
    $log.debug('Loading AdminNavController');

    $scope.close = function() {
        $mdSidenav('right').close().then(function() {
            $log.debug('Closed sidebar.')
        });
    };

    $scope.open = function() {
        $mdSidenav('right').open();
    };

    console.log('opening....');
    setTimeout(function() {
        $mdSidenav('admin-nav').open();
    }, 1000);
}]);
