app.controller('AdminUserController', ['$scope', '$log', '$mdDialog', '$http', function ($scope, $log, $mdDialog, $http) {
    $log.debug('Loading AdminUserController');

    function showEditCreateUserDialog() {
        $mdDialog.show({
            clickOutsideToClose: true,
            scope: $scope,        // use parent scope in template
            preserveScope: true,  // do not forget this if use parent scope
            // Since GreetingController is instantiated with ControllerAs syntax
            // AND we are passing the parent '$scope' to the dialog, we MUST
            // use 'vm.<xxx>' in the template markup
            template: ($('#user-dialog')[0]).innerHTML,
            controller: function DialogController($scope, $mdDialog) {
                $scope.closeDialog = function() {
                    $mdDialog.hide();
                }
            }
        });
    }

    $scope.createUser= function() {
        window.location.href = '/admin/user'
    };

    $scope.showAdmins = function() {
        window.location.href = '/admin/admins'
    };

    $scope.showAll = function() {
        window.location.href = '/admin/users'
    };

    $scope.editUser = function(userId) {
        window.location.href = '/admin/user/' + userId + '?back=' + encodeURIComponent(window.location.href);
    };

    $scope.clearQuery = function(userId) {
        window.location.href = window.location.pathname;
    };

    $scope.userDevices = function(userId) {
        window.location.href = '/admin/user/' + userId + '/devices';
    };

    function handleSubmission(method, url, fn) {
        var error = $mdDialog.alert()
            .title('This is embarrassing!')
            .htmlContent('Sorry! An error occurred. Please try again in a few minutes.<br/>If the error continues, please contact support.')
            .ariaLabel('error occurred')
            .ok('OK');

        $http({
            method: method,
            url: url
        }).then(function successCallback(response) {
            if (fn && typeof fn === 'function') {
                fn.call(null);
            } else if (fn === true) {
                document.location.reload(true);
            }
        }, function errorCallback(response) {
            $mdDialog.show(error);
        });
    }

    $scope.deleteUser = function(user) {
        var confirm = $mdDialog.confirm()
            .title('Delete user?')
            .htmlContent('This will permanently delete the user account for <strong>' + user.name + '</strong>. Are you sure?')
            .ariaLabel('delete user')
            .ok('Yes, delete forever')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            handleSubmission('DELETE', '/admin/user/' + user.id, true);
        });
    };

    $scope.upgradeUser = function(user) {
        var confirm = $mdDialog.confirm()
            .title('Make user an administrator?')
            .htmlContent('Would you like to make <strong>' + user.name + '</strong> an administrator?')
            .ariaLabel('make user an administrator')
            .ok('Yes, make admin')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            user.admin = true;
            //handleSubmission('POST', '/admin/user/' + user.id + '/upgrade');
            window.location.href =  '/admin/user/' + user.id + '/upgrade';
        });
    };

    $scope.downgradeUser = function(user) {
        var confirm = $mdDialog.confirm()
            .title('Remove user\'s administrator privileges?')
            .htmlContent('Would you like to remove admin privileges for <strong>' + user.name + '?</strong>')
            .ariaLabel('remove admin privileges')
            .ok('Yes, remove admin privileges')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(function() {
            user.admin = false;
            window.location.href =  '/admin/user/' + user.id + '/downgrade';
            //handleSubmission('POST', '/admin/user/' + user.id + '/downgrade');
        });
    };

    $scope.submitUser = function() {
        if ($scope.userForm.$pristine || !$scope.userForm.$valid) {
            return;
        }
        $scope.userForm.$submitted = true;
        $('[name="userForm"]').submit();
    };

    $scope.loginAs = function(userId) {
        window.location.href = '/admin/login-as/' + userId;
    };

    Mousetrap($('[name="userForm"]')[0]).bind('enter', function() {
        $scope.submit();
    });

    Mousetrap($('[name="usersSearch"]')[0]).bind('enter', function() {
        $('[name="usersSearch"]').submit();
    });
}]);
