app.directive('wgCompareTo', function() {
    return {
        require: "ngModel",
        scope: {
            otherModelValue: "=wgCompareTo"
        },
        link: function(scope, element, attributes, ngModel) {
            ngModel.$validators.wgCompareTo = function(modelValue) {
                return modelValue == scope.otherModelValue;
            };
            scope.$watch("otherModelValue", function() {
                ngModel.$validate();
            });
        }
    };
});
