app.controller('MobileNavController', ['$scope', '$mdSidenav', '$log', function ($scope, $mdSidenav, $log) {
    $log.debug('Loading MobileNavController');

    $scope.close = function() {
        $mdSidenav('right').close().then(function() {
            $log.debug('Closed sidebar.')
        });
    };

    $scope.open = function() {
        $mdSidenav('right').open();
    };
}]);
