app.controller('SettingsController', ['$scope', '$log', '$mdDialog', function ($scope, $log, $mdDialog) {
    $log.debug('Loading SettingsController');

    $scope.registration = {
        name: ''
    };

    $scope.submitSettings = function() {
        if ($scope.userForm.$pristine || !$scope.userForm.$valid) {
            return;
        }
        $scope.userForm.$submitted = true;
        $('[name="userForm"]').submit();
    };

    $scope.showNewBillingInformation = true;

    function stripeSubscribeResponseHandler (status, response) {
        var error = $mdDialog.alert()
            .title('Error Processing Payment')
            .htmlContent('It looks like there\'s a problem with your billing information.<br/>Please check your billing information and try again.')
            .ariaLabel('error occurred')
            .ok('OK');

        if (response.error) {
            error.htmlContent(response.error.message);
            $mdDialog.show(error);
            $scope.updateBillingForm.$submitted = false;
        } else {
            $('[name="stripe-token"]').val(response.id);
            if ($scope.updateBillingForm.$pristine || !$scope.updateBillingForm.$valid) {
                return;
            }
            $('[name="updateBillingForm"]').submit();
        }
    }

    $scope.submitUpdateBilling = function () {
        $scope.updateBillingForm.$submitted = true;
        Stripe.card.createToken({
            number:         $scope.billing.ccnumber,
            cvc:            $scope.billing.cccvc,
            exp_month:      $scope.billing.ccmonth,
            exp_year:       $scope.billing.ccyear,
            name:           $scope.billing.name,
            address_line1:  $scope.billing.address1,
            address_line2:  $scope.billing.address2,
            city:           $scope.billing.city,
            state:          $scope.billing.state,
            address_zip:    $scope.billing.postalcode,
            country:        $scope.billing.country
        }, stripeSubscribeResponseHandler);
    };

    Mousetrap($('[name="userForm"]')[0]).bind('enter', function() {
        $scope.submit();
    });
}]);
